<template>
  <section class="flex flex-col justify-between mt-8 shadow">
    <div class="border-t-4 border-b-4 border-blue-900">
      <h3 class="text-blue-900 text-lg tracking-wide font-semibold  p-2 rounded-sm uppercase">
        corregedoria
      </h3>
    </div>
    <div class="p-4">
      <!-- <div>
        <img class="sm:w-2/5 rounded-sm shadow-md aspect-square sm:float-right sm:ml-8 mb-4" src="../../assets/preventive.jpg" alt="logo da corregdoria preventiva">
      </div> -->
      <p class="paragraph text-justify">O Departamento de Controle e Correição da Polícia Militar do Distrito Federal, incumbido de empregar esforços para incrementar as atividades disciplinares e correcionais e de fortalecer a estrutura de controle das condutas criminais, disciplinares e ético-profissionais praticadas pelos Policiais Militares, tem por prioridade atual cumprir as atribuições inerentes aos sistemas de controle interno, correição, polícia judiciária militar e de fiscalização atuando de forma preventiva, com foco no desempenho da gestão.</p>
      <p class="paragraph text-justify">A fim de fortalecer os mecanismos de qualidade no atendimento e na transparência da atividade policial militar, serão empregadas ferramentas capazes de viabilizar o contato com o Departamento de Controle e Correição e a divulgação de dados úteis aos policiais militares referentes à prática correcional para promover a disciplina.</p>
      <p class="paragraph text-justify">O aperfeiçoamento da iniciativa preventiva, por meio da interação do Departamento de Controle e Correição com o efetivo, ao incrementar e uniformizar as práticas correcionais, reduz a instauração de procedimentos, a demora das apurações, bem como a exposição a desgastes desnecessários. As medidas contribuem para a diminuição das práticas irregulares, para a qualidade da atividade fim da policial militar.</p>
    </div>
    <div class="bg-gray-300">
      <h3 class="text-gray-600 text-sm font-semibold tracking-wide p-2 rounded-sm uppercase">
        ações:
      </h3>
    </div>
    <div class="m-4">
      <ul class="list-disc m-4">
        <li class="mb-2 text-justify">Orientar, atualizar e amparar o efetivo quanto ao exercício da atividade correcional;</li>
        <li class="mb-2 text-justify">Uniformização do trabalho desenvolvido nas Subseções de Justiça e Disciplina;</li>
        <li class="mb-2 text-justify">Agregar ferramentas para aumentar a interação entre o Corregedor-Geral da PMDF e o efetivo, auxiliando na compreensão da perspectiva dos policiais militares sobre as ações correcionais;</li>
        <li class="mb-2 text-justify">Atualização do repositório normativo pertinente.</li>
      </ul>
    </div>
    <div class="bg-gray-300">
      <h3 class="text-gray-600 text-sm font-semibold tracking-wide p-2 rounded-sm uppercase">
        finalidade:
      </h3>
    </div>
     <div class="m-4">
      <ul class="list-disc p-4">
        <li class="mb-2 text-justify">Dissuadir a prática de irregularidades;</li>
        <li class="mb-2 text-justify">Zelar pela regularidade e celeridade das apurações correcionais;</li>
        <li class="mb-2 text-justify">Contribuir para o fortalecimento da integridade e transparência da corporação;</li>
        <li class="mb-2 text-justify">Favorecer a troca de experiências para a melhoria das atividades de controle e melhoria da qualidade do serviço policial militar.</li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
  .paragraph {
    text-indent: 2rem;
    margin-bottom: 1rem;
  }
</style>